import '../styles/privacy-policy-page.scss';

import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import HeroSection from '../components/hero-section';
import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacyPolicyPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'privacyPolicy.SEO_TITLE' })} />

      <main id="privacy-policy-page">
        <HeroSection headingId="privacyPolicy.HERO_TITLE" />
        <section className="section-container section-light">
          <div className="container">
            {intl.locale === 'de' ? (
              <>
                <article className="group">
                  <h4>Datenschutzerklärung</h4>
                  <p>
                    Wir freuen uns über Ihr Interesse an unserer Website. Der
                    Schutz Ihrer Privatsphäre ist für uns sehr wichtig.
                    Nachstehend informieren wir Sie ausführlich über den Umgang
                    mit Ihren Daten.
                  </p>
                </article>

                <article className="group">
                  <h4>Speicherung von Zugriffsdaten in Server-Logfiles</h4>
                  <p>
                    Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer
                    Person zu machen. Wir speichern lediglich Zugriffsdaten in
                    sogenannten Server-Logfiles, wie z.B. den Namen der
                    angeforderten Datei, Datum und Uhrzeit des Abrufs,
                    übertragene Datenmenge und den anfragenden Provider. Diese
                    Daten werden ausschließlich zur Sicherstellung eines
                    störungsfreien Betriebs der Seite und zur Verbesserung
                    unseres Angebots ausgewertet und erlauben uns keinen
                    Rückschluss auf Ihre Person.
                  </p>
                </article>

                <article className="group">
                  <h4>
                    Datenerhebung und -verwendung zur Vertragsabwicklung und bei
                    Eröffnung eines Kundenkontos
                  </h4>
                  <p>
                    Wir erheben personenbezogene Daten, wenn Sie uns diese im
                    Rahmen Ihrer Bestellung, bei einer Kontaktaufnahme mit uns
                    (z.B. per Kontaktformular oder E-Mail) oder bei Eröffnung
                    eines Kundenkontos freiwillig mitteilen. Welche Daten
                    erhoben werden, ist aus den jeweiligen Eingabeformularen
                    ersichtlich. Wir verwenden die von Ihnen mitgeteilten Daten
                    zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach
                    vollständiger Abwicklung des Vertrages oder Löschung Ihres
                    Kundenkontos werden Ihre Daten für die weitere Verwendung
                    gesperrt und nach Ablauf der steuer- und handelsrechtlichen
                    Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich
                    in eine weitere Nutzung Ihrer Daten eingewilligt haben oder
                    wir uns eine darüber hinausgehende Datenverwendung
                    vorbehalten, die gesetzlich erlaubt ist und über die wir Sie
                    nachstehend informieren. Die Löschung Ihres Kundenkontos ist
                    jederzeit möglich und kann entweder durch eine Nachricht an
                    die unten beschriebene Kontaktmöglichkeit oder über eine
                    dafür vorgesehene Funktion im Kundenkonto erfolgen.
                  </p>
                </article>

                <article className="group">
                  <h4>Datenweitergabe zur Vertragserfüllung</h4>
                  <p>
                    Zur Vertragserfüllung geben wir Ihre Daten an das mit der
                    Lieferung beauftragte Versandunternehmen weiter, soweit dies
                    zur Lieferung bestellter Waren erforderlich ist. Je nach
                    dem, welchen Zahlungsdienstleister Sie im Bestellprozess
                    auswählen, geben wir zur Abwicklung von Zahlungen die
                    hierfür erhobenen Zahlungsdaten an das mit der Zahlung
                    beauftragte Kreditinstitut und ggf. von uns beauftragte
                    Zahlungsdienstleister weiter bzw. an den ausgewählten
                    Zahlungsdienst. Zum Teil erheben die ausgewählten
                    Zahlungsdienstleister diese Daten auch selbst, soweit Sie
                    dort ein Konto anlegen. In diesem Fall müssen Sie sich im
                    Bestellprozess mit Ihren Zugangsdaten bei dem
                    Zahlungsdienstleister anmelden. Es gilt insoweit die
                    Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.
                  </p>
                </article>

                <article className="group">
                  <h4>Datenverwendung bei Anmeldung zum E-Mail-Newsletter</h4>
                  <p>
                    Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir
                    die hierfür erforderlichen oder gesondert von Ihnen
                    mitgeteilten Daten, um Ihnen regelmäßig unseren
                    E-Mail-Newsletter entsprechend Ihrer Einwilligung
                    zuzusenden. Die Abmeldung vom Newsletter ist jederzeit
                    möglich und kann entweder durch eine Nachricht an die unten
                    beschriebene Kontaktmöglichkeit oder über einen dafür
                    vorgesehenen Link im Newsletter erfolgen.
                  </p>
                </article>

                <article className="group">
                  <h4>Verwendung von Cookies</h4>
                  <p>
                    Um den Besuch unserer Website attraktiv zu gestalten und die
                    Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir
                    auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt
                    es sich um kleine Textdateien, die auf Ihrem Endgerät
                    gespeichert werden. Einige der von uns verwendeten Cookies
                    werden nach Ende der Browser-Sitzung, also nach Schließen
                    Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies).
                    Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen
                    uns, Ihren Browser beim nächsten Besuch wiederzuerkennen
                    (persistente Cookies). Sie können Ihren Browser so
                    einstellen, dass Sie über das Setzen von Cookies informiert
                    werden und einzeln über deren Annahme entscheiden oder die
                    Annahme von Cookies für bestimmte Fälle oder generell
                    ausschließen. Bei der Nichtannahme von Cookies kann die
                    Funktionalität unserer Website eingeschränkt sein.
                  </p>
                </article>

                <article className="group">
                  <h4>Verwendung von Cookies</h4>
                  <p>
                    Um den Besuch unserer Website attraktiv zu gestalten und die
                    Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir
                    auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt
                    es sich um kleine Textdateien, die auf Ihrem Endgerät
                    gespeichert werden. Einige der von uns verwendeten Cookies
                    werden nach Ende der Browser-Sitzung, also nach Schließen
                    Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies).
                    Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen
                    uns, Ihren Browser beim nächsten Besuch wiederzuerkennen
                    (persistente Cookies). Sie können Ihren Browser so
                    einstellen, dass Sie über das Setzen von Cookies informiert
                    werden und einzeln über deren Annahme entscheiden oder die
                    Annahme von Cookies für bestimmte Fälle oder generell
                    ausschließen. Bei der Nichtannahme von Cookies kann die
                    Funktionalität unserer Website eingeschränkt sein.
                  </p>
                </article>

                <article className="group">
                  <h4>
                    Verwendung von Social Plugins von Facebook unter Verwendung
                    der Shariff-Lösung
                  </h4>
                  <p>
                    Auf unserer Website werden Social Plugins („Plugins“) von
                    sozialen Netzwerken verwendet. Um den Schutz Ihrer Daten
                    beim Besuch unserer Website zu erhöhen, sind die Plugins
                    nicht uneingeschränkt, sondern lediglich unter Verwendung
                    eines HTML-Links (sogenannte „Shariff-Lösung“ von c‘t) in
                    die Seite eingebunden. Diese Einbindung gewährleistet, dass
                    beim Aufruf einer Seite unseres Webauftritts, die solche
                    Plugins enthält, noch keine Verbindung mit den Servern des
                    Anbieters des jeweiligen sozialen Netzwerks hergestellt
                    wird. Klicken Sie auf einen der Buttons, öffnet sich ein
                    neues Fenster Ihres Browsers und ruft die Seite des
                    jeweiligen Diensteanbieters auf, auf der Sie (ggf. nach
                    Eingabe Ihrer Login-Daten) z.B. den Like- oder Share-Button
                    betätigen können. Zweck und Umfang der Datenerhebung und die
                    weitere Verarbeitung und Nutzung der Daten durch die
                    Anbieter auf deren Seiten sowie Ihre diesbezüglichen Rechte
                    und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre
                    entnehmen Sie bitte den Datenschutzhinweisen der Anbieter.
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href="http://www.facebook.com/policy"
                      rel="noopener noreferrer"
                      aria-label="facebook policy"
                    >
                      http://www.facebook.com/policy
                    </a>
                  </p>
                </article>

                <article className="group">
                  <h4>Google Analytics</h4>
                  <p>
                    Google Universal Analytics mit demografischen Merkmalen
                    Diese Website benutzt Google Analytics, einen
                    Webanalysedienst der Google Ireland Limited, Gordon House, 4
                    Barrow St, Dublin, D04 E5W5, Irland („Google“). Google
                    Analytics verwendet sog. Cookies, das sind Textdateien, die
                    auf Ihrem Computer gespeichert werden und die eine Analyse
                    der Benutzung der Website durch Sie ermöglichen. Die durch
                    das Cookie erzeugten Informationen über Ihre Benutzung
                    dieser Website (einschließlich der gekürzten IP-Adresse)
                    werden in der Regel an einen Server von Google übertragen
                    und dort gespeichert, hierbei kann es auch zu einer
                    Übermittlung an die Server der Google LLC. in den USA
                    kommen. Diese Website verwendet Google Analytics
                    ausschließlich mit der Erweiterung „_anonymizeIp()“, die
                    eine Anonymisierung der IP-Adresse durch Kürzung
                    sicherstellt und eine direkte Personenbeziehbarkeit
                    ausschließt. Durch die Erweiterung wird Ihre IP-Adresse von
                    Google innerhalb von Mitgliedstaaten der Europäischen Union
                    oder in anderen Vertragsstaaten des Abkommens über den
                    Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
                    Ausnahmefällen wird die volle IP-Adresse an einen Server von
                    Google LLC.in den USA übertragen und dort gekürzt. In diesen
                    Ausnahmefällen erfolgt diese Verarbeitung gemäß Art. 6 Abs.
                    1 lit. f DSGVO auf Grundlage unseres berechtigten Interesses
                    an der statistischen Analyse des Nutzerverhaltens zu
                    Optimierungs- und Marketingzwecken.
                  </p>
                  <p>
                    In unserem Auftrag wird Google diese Informationen benutzen,
                    um Ihre Nutzung der Website auszuwerten, um Reports über die
                    Websiteaktivitäten zusammenzustellen und um weitere mit der
                    Websitenutzung und der Internetnutzung verbundene
                    Dienstleistungen uns gegenüber zu erbringen. Die im Rahmen
                    von Google Analytics von Ihrem Browser übermittelte
                    IP-Adresse wird nicht mit anderen Daten von Google
                    zusammengeführt. Sie können die Speicherung der Cookies
                    durch eine entsprechende Einstellung Ihrer Browser-Software
                    verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in
                    diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
                    Website vollumfänglich nutzen können. Sie können darüber
                    hinaus die Erfassung der durch das Cookie erzeugten und auf
                    Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                    IP-Adresse) an Google sowie die Verarbeitung dieser Daten
                    durch Google verhindern, indem Sie das unter dem folgenden
                    Link verfügbare Browser-Plugin herunterladen und
                    installieren: https://tools.google.com/dlpage/gaoptout?hl=de
                    Alternativ zum Browser-Plugin oder innerhalb von Browsern
                    auf mobilen Geräten klicken Sie bitte auf den folgenden
                    Link, um ein Opt-Out-Cookie zu setzen, der die Erfassung
                    durch Google Analytics innerhalb dieser Website zukünftig
                    verhindert (dieses Opt-Out-Cookie funktioniert nur in diesem
                    Browser und nur für diese Domain, löschen Sie Ihre Cookies
                    in diesem Browser, müssen Sie diesen Link erneut klicken):
                    Google Analytics deaktivieren Für den Fall der Übermittlung
                    von personenbezogenen Daten an die Google LLC. mit Sitz in
                    den USA, hat sich Google LLC. für das us-europäische
                    Datenschutzübereinkommen „Privacy Shield“ zertifiziert,
                    welches die Einhaltung des in der EU geltenden
                    Datenschutzniveaus gewährleistet. Ein aktuelles Zertifikat
                    kann hier eingesehen werden:
                    https://www.privacyshield.gov/list
                  </p>
                  <p>
                    Diese Website verwendet Google Analytics zudem für eine
                    geräteübergreifende Analyse von Besucherströmen, die über
                    eine User-ID durchgeführt wird. Beim erstmaligen Aufrufen
                    einer Seite wird dem Nutzer eine eindeutige, dauerhafte und
                    anonymisierte ID zugeteilt, die geräteübergreifend gesetzt
                    wird. Dies ermöglicht es, Interaktionsdaten von
                    verschiedenen Geräten und aus unterschiedlichen Sitzungen
                    einem einzelnen Nutzer zuzuordnen. Die User-ID enthält keine
                    personenbezogenen Daten und übermittelt solche auch nicht an
                    Google. Der Datenerhebung und -speicherung über die User-ID
                    kann jederzeit mit Wirkung für die Zukunft widersprochen
                    werden. Hierfür müssen Sie Google Analytics auf allen
                    Systemen deaktivieren, die Sie nutzen, beispielsweise in
                    einem anderen Browser oder auf Ihrem mobilen Endgerät.
                  </p>
                  <p>
                    Diese Website nutzt des Weiteren die Funktion “demografische
                    Merkmale” von Google Analytics. Hierdurch können Berichte
                    erstellt werden, die Aussagen zu demografischen Daten wie
                    Alter, Geschlecht und Interessen der Seitenbesucher
                    enthalten. Diese Daten stammen aus interessenbezogener
                    Werbung von Google, dem Google-Display-Netzwerk, sowie aus
                    Besucherdaten von Drittanbietern. Sie können diese Funktion
                    jederzeit über die Anzeigeneinstellungen in Ihrem
                    Google-Konto deaktivieren oder die Erfassung Ihrer Daten
                    durch Google Analytics wie sogleich dargestellt generell
                    untersagen.
                  </p>
                  <p>
                    Die Deaktivierung können Sie mithilfe eines Browser-Plugins
                    von Google (https://tools.google.com/dlpage/gaoptout?hl=de)
                    vornehmen. Alternativ zum Browser-Plugin oder innerhalb von
                    Browsern auf mobilen Geräten klicken Sie bitte auf den
                    folgenden Link, um ein Opt-Out-Cookie zu setzen, das die
                    Erfassung durch Google Analytics innerhalb dieser Website
                    zukünftig verhindert (dieses Opt-Out-Cookie funktioniert nur
                    in diesem Browser und nur für diese Domain, löschen Sie Ihre
                    Cookies in diesem Browser, müssen Sie diesen Link erneut
                    klicken): Google Analytics deaktivieren Weitere Hinweise zu
                    Google (Universal) Analytics finden Sie hier:
                    https://support.google.com/analytics/answer/2838718?hl=de&ref_topic=6010376
                  </p>
                </article>

                <article className="group">
                  <h4>
                    Facebook Pixel für die Erstellung von Custom Audiences
                  </h4>
                  <p>
                    Innerhalb unseres Onlineangebotes wird das sog.
                    „Facebook-Pixel“ des sozialen Netzwerkes Facebook
                    eingesetzt, welches von der Facebook Ireland Limited, 4
                    Grand Canal Quare, Dublin 2, Irland („Facebook“) betrieben
                    wird. Klickt ein Nutzer auf eine von uns geschaltete
                    Werbeanzeige, die bei Facebook ausgespielt wird, wird der
                    URL unserer verknüpften Seite durch Facebook Pixel ein
                    Zusatz angefügt. Sofern unsere Seite über Pixel das Teilen
                    von Daten mit Facebook erlaubt, wird dieser URL-Parameter in
                    den Browser des Nutzers per Cookie eingeschrieben, welches
                    unsere verknüpfte Seite selbst setzt. Dieses Cookie wird von
                    Facebook Pixel sodann ausgelesen und ermöglicht eine
                    Weiterleitung der Daten an Facebook. Mit Hilfe des
                    Facebook-Pixels ist es Facebook einerseits möglich, die
                    Besucher unseres Onlineangebotes als Zielgruppe für die
                    Darstellung von Anzeigen (sog. „Facebook-Ads“) zu bestimmen.
                    Dementsprechend setzen wir das Facebook-Pixel ein, um die
                    durch uns geschalteten Facebook-Ads nur solchen
                    Facebook-Nutzern anzuzeigen, die auch ein Interesse an
                    unserem Onlineangebot gezeigt haben oder die bestideakmmte
                    Merkmale (z.B. Interessen an bestimmten Themen oder
                    Produkten, die anhand der besuchten Webseiten bestimmt
                    werden) aufweisen, welche wir an Facebook übermitteln (sog.
                    „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten
                    wir ebenfalls sicherstellen, dass unsere Facebook-Ads dem
                    potentiellen Interesse der Nutzer entsprechen und nicht
                    belästigend wirken. So können wir weiter die Wirksamkeit der
                    Facebook-Werbeanzeigen für statistische und
                    Marktforschungszwecke auswerten, indem wir nachvollziehen,
                    ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf
                    unsere Website weitergeleitet wurden (sog. „Conversion“).
                    Die erhobenen Daten sind für uns anonym, bieten uns also
                    keine Rückschlüsse auf die Identität der Nutzer. Allerdings
                    werden die Daten von Facebook gespeichert und verarbeitet,
                    sodass eine Verbindung zum jeweiligen Nutzerprofil möglich
                    ist und Facebook die Daten für eigene Werbezwecke,
                    entsprechend der Facebook- Datenverwendungsrichtlinie{` `}
                    <a
                      target="_blank"
                      href="https://www.facebook.com/about/privacy/"
                      rel="noopener noreferrer"
                      aria-label="facebook policy"
                    >
                      https://www.facebook.com/about/privacy/
                    </a>{' '}
                    verwenden kann. Die Daten können Facebook sowie dessen
                    Partnern das Schalten von Werbeanzeigen auf und außerhalb
                    von Facebook ermöglichen. Die mit dem Einsatz des Facebook
                    Pixels einhergehenden Datenverarbeitungen erfolgen aufgrund
                    unseres überwiegenden berechtigten Interesses an der
                    Auswertung, der Optimierung und des wirtschaftlichen
                    Betriebs unseres Online-Angebots sowie unserer
                    Werbemaßnahmen gemäß Art. 6 Abs. 1 lit. f DSGVO. Die durch
                    Facebook erzeugten Informationen werden in der Regel an
                    einen Server von Facebook übertragen und dort gespeichert,
                    hierbei kann es auch zu einer Übermittlung an die Server der
                    Facebook Inc. in den USA kommen. Facebook Inc. mit Sitz in
                    den USA ist für das us-europäische Datenschutzübereinkommen
                    „Privacy Shield“ zertifiziert, welches die Einhaltung des in
                    der EU geltenden Datenschutzniveaus gewährleistet. Um der
                    Erfassung durch den Facebook-Pixel und der Verwendung Ihrer
                    Daten zur Darstellung von Facebook-Ads insgesamt zu
                    widersprechen, können Sie durch Klick auf den nachstehenden
                    Link ein Opt-Out-Cookie setzen, welches das Facebook
                    Pixel-Tracking deaktiviert:
                  </p>
                  <p>
                    Facebook Pixel Deaktivieren <br />
                    Dieses Opt-Out-Cookie funktioniert nur in diesem Browser und
                    nur für diese Domain. Löschen Sie Ihre Cookies in diesem
                    Browser, müssen Sie den obigen Link erneut anklicken.
                  </p>
                </article>

                <article className="group">
                  <h4>Auskunftsrecht und Kontaktmöglichkeit</h4>
                  <p>
                    Sie haben ein Recht auf unentgeltliche Auskunft über die bei
                    uns zu Ihrer Person gespeicherten Daten sowie ggf. ein Recht
                    auf Berichtigung, Sperrung oder Löschung dieser Daten. Bei
                    Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer
                    personenbezogenen Daten, bei Auskünften, Berichtigung,
                    Sperrung oder Löschung von Daten sowie Widerruf erteilter
                    Einwilligungen oder Widerspruch gegen eine bestimmte
                    Datenverwendung wenden Sie sich bitte direkt an uns über die
                    Kontaktdaten in unserem Impressum.
                  </p>
                </article>
              </>
            ) : (
              <>
                <article className="group">
                  <h4>Privacy policy</h4>
                  <p>
                    Thank you for your interest in our website. The protection
                    of your privacy is very important to us. Below we inform you
                    in detail about how we handle your data.
                  </p>
                </article>

                <article className="group">
                  <h4>Storage of access data in server log files</h4>
                  <p>
                    You can visit our websites without giving any personal
                    information. We only store access data in so-called server
                    log files, such as the name of the requested file, date and
                    time of the request, transferred data volume and the
                    requesting provider. These data are evaluated exclusively to
                    ensure trouble-free operation of the site and to improve our
                    offer and do not allow us to draw any conclusions about your
                    person.
                  </p>
                </article>

                <article className="group">
                  <h4>
                    Data collection and use for contract processing and opening
                    a customer account
                  </h4>
                  <p>
                    We collect personal data when you voluntarily provide us
                    with this information as part of your order, when contacting
                    us (e.g. via contact form or e-mail) or when opening a
                    customer account. Which data is collected can be seen from
                    the respective input forms. We use the data you provide us
                    with for the purpose of contract processing and handling
                    your inquiries. After complete processing of the contract or
                    deletion of your customer account, your data will be blocked
                    for further use and deleted after expiry of the tax and
                    commercial law retention periods, unless you have expressly
                    consented to further use of your data or we reserve the
                    right to use your data for other purposes which are
                    permitted by law and about which we inform you below. The
                    deletion of your customer account is possible at any time
                    and can be done either by sending a message to the contact
                    option described below or by using a function in the
                    customer account intended for this purpose.
                  </p>
                </article>

                <article className="group">
                  <h4>Data transfer for contract fulfillment</h4>
                  <p>
                    In order to fulfil the contract, we will pass on your data
                    to the shipping company commissioned with the delivery,
                    insofar as this is necessary for the delivery of ordered
                    goods. Depending on the payment service provider you select
                    in the order process, we will pass on the payment data
                    collected for this purpose to the bank commissioned with the
                    payment and, if applicable, to the payment service provider
                    commissioned by us or to the selected payment service for
                    the processing of payments. In some cases, the selected
                    payment service providers may also collect this data
                    themselves if you open an account there. In this case, you
                    must log on to the payment service provider with your access
                    data during the ordering process. In this respect, the data
                    protection declaration of the respective payment service
                    provider applies.
                  </p>
                </article>

                <article className="group">
                  <h4>
                    Use of data when registering for the e-mail newsletter
                  </h4>
                  <p>
                    If you register for our newsletter, we will use the data
                    required for this purpose or data provided separately by you
                    to send you our e-mail newsletter regularly in accordance
                    with your consent. You can unsubscribe from the newsletter
                    at any time, either by sending a message to the contact
                    option described below or by using a link provided for this
                    purpose in the newsletter.
                  </p>
                </article>

                <article className="group">
                  <h4>Use of cookies</h4>
                  <p>
                    In order to make visiting our website attractive and to
                    enable the use of certain functions, we use so-called
                    cookies on various pages. These are small text files that
                    are stored on your end device. Some of the cookies we use
                    are deleted again after the end of the browser session, i.e.
                    after closing your browser (so-called session cookies).
                    Other cookies remain on your terminal device and enable us
                    to recognize your browser the next time you visit us
                    (persistent cookies). You can set your browser so that you
                    are informed about the setting of cookies and decide
                    individually whether to accept them or to exclude the
                    acceptance of cookies for certain cases or in general. If
                    cookies are not accepted, the functionality of our website
                    may be limited.
                  </p>
                </article>

                <article className="group">
                  <h4>
                    Use of social plugins from Facebook using the Shariff
                    solution
                  </h4>
                  <p>
                    Our website uses social plugins (“plugins”) from social
                    networks. In order to increase the protection of your data
                    when visiting our website, the plugins are not fully
                    integrated into the page, but only by using an HTML link
                    (so-called “Shariff solution” by c’t). This integration
                    ensures that when a page of our website containing such
                    plugins is called up, no connection is yet established with
                    the servers of the provider of the respective social
                    network. Click on one of the buttons, a new window of your
                    browser opens and calls up the page of the respective
                    service provider, where you can (if necessary after entering
                    your login data) e.g. press the Like or Share button. For
                    the purpose and scope of data collection and the further
                    processing and use of data by the providers on their sites
                    as well as your rights and setting options for the
                    protection of your privacy, please refer to the data
                    protection information of the providers.
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href="http://www.facebook.com/policy"
                      rel="noopener noreferrer"
                      aria-label="facebook policy"
                    >
                      http://www.facebook.com/policy
                    </a>
                  </p>
                </article>

                <article className="group">
                  <h4>Google Analytics</h4>
                  <p>
                    Google Universal Analytics with demographic characteristics
                    This website uses Google Analytics, a web analytics service
                    provided by Google Ireland Limited, Gordon House, 4 Barrow
                    St, Dublin, D04 E5W5, Ireland (“Google”). Google Analytics
                    uses so-called cookies, i.e. text files that are stored on
                    your computer and which enable an analysis of your use of
                    the website. The information generated by the cookie about
                    your use of this website (including the abbreviated IP
                    address) is usually transferred to a Google server and
                    stored there. This may also result in a transfer to the
                    servers of Google LLC. in the USA.
                  </p>
                  <p>
                    This website uses Google Analytics exclusively with the
                    extension “_anonymizeIp()“, which ensures anonymization of
                    the IP address by shortening it and excludes direct personal
                    reference. Through the extension your IP address will be
                    shortened by Google within member states of the European
                    Union or in other contracting states of the Agreement on the
                    European Economic Area before. Only in exceptional cases
                    will the full IP address be transferred to a Google LLC.
                    server in the USA and shortened there. In these exceptional
                    cases, this processing is carried out in accordance with
                    Art. 6 para. 1 lit. f DSGVO on the basis of our legitimate
                    interest in the statistical analysis of user behaviour for
                    optimisation and marketing purposes.
                  </p>
                  <p>
                    On our behalf, Google will use this information to evaluate
                    your use of the website, to compile reports on website
                    activities and to provide us with further services related
                    to website and Internet use. The IP address transmitted by
                    your browser within the scope of Google Analytics is not
                    merged with other Google data.
                  </p>
                  <p>
                    You can prevent the storage of cookies by adjusting your
                    browser software accordingly. However, we would like to
                    point out that in this case you may not be able to use all
                    functions of this website to their full extent. You can also
                    prevent the collection of data generated by the cookie and
                    related to your use of the website (including your IP
                    address) to Google and the processing of this data by Google
                    by downloading and installing the browser plugin available
                    under the following link
                    <a
                      target="_blank"
                      href="https://tools.google.com/dlpage/gaoptout?hl=en"
                      rel="noopener noreferrer"
                      aria-label="google gaoptout"
                    >
                      https://tools.google.com/dlpage/gaoptout?hl=en
                    </a>
                    As an alternative to the browser plugin or within browsers
                    on mobile devices, please click on the following link to set
                    an opt-out cookie that will prevent Google Analytics from
                    collecting data within this website in the future (this
                    opt-out cookie only works in this browser and only for this
                    domain, if you delete your cookies in this browser, you will
                    have to click this link again) Disable Google Analytics.
                  </p>
                  <p>
                    In the event that personal data is transferred to Google
                    LLC. with its registered office in the USA, Google LLC. has
                    certified itself for the us European data protection
                    agreement “Privacy Shield”, which guarantees compliance with
                    the level of data protection applicable in the EU. A current
                    certificate can be viewed here:{' '}
                    <a
                      target="_blank"
                      href="https://www.privacyshield.gov/list"
                      rel="noopener noreferrer"
                      aria-label="privacy shield list"
                    >
                      https://www.privacyshield.gov/list
                    </a>{' '}
                    This website also uses Google Analytics for a cross-device
                    analysis of visitor flows, which is carried out using a user
                    ID. When a page is called up for the first time, the user is
                    assigned a unique, permanent and anonymous ID that is set
                    across all devices. This makes it possible to assign
                    interaction data from different devices and from different
                    sessions to a single user. The user ID does not contain any
                    personal data and does not transmit any personal data to
                    Google. The collection and storage of data via the User ID
                    can be revoked at any time with effect for the future. To do
                    so, you must deactivate Google Analytics on all systems that
                    you use, for example in another browser or on your mobile
                    device. (edited)
                  </p>

                  <p>
                    This website also uses the “demographic features” function
                    of Google Analytics. This allows reports to be generated
                    that contain statements on demographic data such as age,
                    gender and interests of the site visitors. This data is
                    derived from interest-based advertising from Google, the
                    Google Display Network, and from third-party visitor data.
                    You can disable this feature at any time in your Google
                    Account’s ad settings, or generally prohibit Google
                    Analytics from collecting your information as shown below.
                    You can deactivate this function using a Google browser
                    plugin
                    <a
                      target="_blank"
                      href="https://tools.google.com/dlpage/gaoptout?hl=en"
                      rel="noopener noreferrer"
                      aria-label="google tools gaoptout"
                    >
                      https://tools.google.com/dlpage/gaoptout?hl=en
                    </a>
                    . As an alternative to the browser plugin or within browsers
                    on mobile devices, please click on the following link to set
                    an opt-out cookie that will prevent Google Analytics from
                    collecting data within this website in the future (this
                    opt-out cookie only works in this browser and only for this
                    domain; if you delete your cookies in this browser, you will
                    have to click this link again): Disable Google Analytics
                    Further information on Google (Universal) Analytics can be
                    found here:{` `}
                    <a
                      target="_blank"
                      href="https://support.google.com/analytics/answer/2838718?hl=de&ref_topic=6010376"
                      rel="noopener noreferrer"
                      aria-label="google support"
                    >
                      https://support.google.com/analytics/answer/2838718?hl=de&ref_topic=6010376
                    </a>
                  </p>
                </article>

                <article className="group">
                  <h4>Facebook pixel for the creation of custom audiences</h4>
                  <p>
                    Within our online offer the so-called “Facebook pixel” of
                    the social network Facebook is used, which is operated by
                    Facebook Ireland Limited, 4 Grand Canal Quare, Dublin 2,
                    Ireland (“Facebook”). If a user clicks on an advertisement
                    placed by us, which is played on Facebook, an addition is
                    added to the URL of our linked page by Facebook pixel. To
                    the extent that our pixel page allows data to be shared with
                    Facebook, this URL parameter is written to the user’s
                    browser via a cookie that our linked page itself sets. This
                    cookie is then read by Facebook Pixel and enables the data
                    to be forwarded to Facebook. With the help of the Facebook
                    pixel, Facebook is on the one hand able to determine the
                    visitors of our online offer as a target group for the
                    presentation of ads (so-called “Facebook ads”). Accordingly,
                    we use the Facebook pixel to display the Facebook ads placed
                    by us only to those Facebook users who have also shown an
                    interest in our online offer or who have specific
                    characteristics (e.g. interests in certain topics or
                    products determined by the websites visited), which we
                    transmit to Facebook (so-called “custom audiences”). With
                    the help of the Facebook pixel, we also want to ensure that
                    our Facebook ads correspond to the potential interest of the
                    users and do not appear to be annoying. This allows us to
                    further evaluate the effectiveness of Facebook ads for
                    statistical and market research purposes by tracking whether
                    users have been redirected to our website after clicking on
                    a Facebook ad (so-called “conversion”). The data collected
                    is anonymous to us, so we cannot draw any conclusions about
                    the identity of the users. However, the data is stored and
                    processed by Facebook so that a connection to the respective
                    user profile is possible and Facebook can use the data for
                    its own advertising purposes, in accordance with the
                    Facebook Data Usage Guidelines{' '}
                    <a
                      target="_blank"
                      href="https://www.facebook.com/about/privacy/"
                      rel="noopener noreferrer"
                      aria-label="facebook policy"
                    >
                      https://www.facebook.com/about/privacy/
                    </a>
                    . The data may enable Facebook and its partners to place
                    advertisements on and outside of Facebook. The data
                    processing associated with the use of the Facebook Pixel is
                    based on our predominantly legitimate interest in the
                    evaluation, optimization and economic operation of our
                    online offering and our advertising measures in accordance
                    with Art. 6 para. 1 lit. f DSGVO. The information generated
                    by Facebook is usually transferred to a Facebook server and
                    stored there, this may also result in a transfer to the
                    servers of Facebook Inc. in the USA. Facebook Inc. with
                    headquarters in the USA is certified for the us European
                    data protection agreement “Privacy Shield”, which guarantees
                    compliance with the data protection level applicable in the
                    EU. In order to object to the collection by the Facebook
                    pixel and the use of your data to display Facebook ads in
                    general, you can set an opt-out cookie by clicking on the
                    link below, which deactivates Facebook pixel tracking:
                  </p>
                  <p>
                    Disable Facebook Pixel This opt-out cookie only works in
                    this browser and only for this domain. If you delete your
                    cookies in this browser, you must click the above link
                    again.
                  </p>
                </article>

                <article className="group">
                  <h4>Right to information and contact</h4>
                  <p>
                    You have a right to free information about the data stored
                    by us about your person as well as a right to correction,
                    blocking or deletion of this data. If you have any questions
                    regarding the collection, processing or use of your personal
                    data, for information, correction, blocking or deletion of
                    data as well as revocation of consents granted or objection
                    to a specific use of data, please contact us directly via
                    the contact details in our imprint.
                  </p>
                </article>
              </>
            )}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default PrivacyPolicyPage;
